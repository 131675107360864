<script>
let isPartner = true,
	ths
export default {
	setIsPartner(val, thisVal) {
		isPartner = val
		ths = thisVal
	},
	getData() {
		let inf = [
				//변경 시 slice로 가져오는 것들 순서 변경해줘야 할 수도 있다
				{ title: '추가/삭제', size: 3 },
				{
					title: '상품명',
					key: 'productName',
					size: 16,
					isSpan: 1,
				},
				{ title: '상위옵션', key: 'productOption1', size: 10 },
				{ title: '하위옵션', key: 'productOption2', size: 10 },
				{
					title: '재고',
					key: 'stock',
					model: 'stock',
					size: 4,
					tooltip: '공구 진행 시 수용 가능한 재고량을 세팅해 주세요.<br> - 옵션별로 설정해주시면 됩니다.',
				},
				{ title: '소비자가', key: 'customerPrice', size: 4 },
				{
					title: '판매가',
					key: 'salePrice',
					size: 4,
					tooltip:
						'판매가는 온라인 최저가 대비 최소 10~15% 이상의 할인률을 적용해 주세요.<br>※최저가로 발생하는 문제를 방지하기 위함입니다.',
				},
				{ title: '내돈내산가', key: 'selfDealPrice', size: 4 },
				{ title: '협찬<br>공급가', key: 'sponsoredSupplyPrice', size: 4 },
				{ title: '매입가', key: 'purchasePrice', size: 4 },
				{ title: '핫티수수료', key: 'hottistCharge', size: 4 },
				{ title: '핫트수수료', key: 'hottCharge', size: 4 },
				{
					title: '면세 여부',
					key: 'isTaxFree',
					size: 2,
					isSpan: 1,
				},
				{ title: '사용 여부', key: 'saveStatus', size: 2 },
				{ title: '추가 수수료', key: 'isChargeAdd', size: 2 },
				{
					title: '무상 협찬',
					key: 'showSponsoredSupply',
					size: 2,
					isSpan: 1,
				},
				{
					title: '내돈 내산',
					key: 'showSelfDeal',
					size: 2,
					isSpan: 1,
				},
				{ title: '옵션 순서', key: 'productSequence', size: 2 },
			],
			input = {
				brandName: '',
				displayProductName: '',
				customerPrice: '',
				salePrice: '',
				selfDealPrice: '',
				rentalPrice: '',
				onelineCont: '',
				rentalMonth: 0,

				productType: 1,

				category: [],
				category1: '',
				category2: '',
				category3: '',
				hashTag: '',

				isSelfDeal: false,
				isApplySelfDeal: false,

				isCombinedDelivery: true,
				deliveryType: 3,
				deliveryPrice: '3,000',
				deliveryFreePrice: '10,000',

				jejuDeliveryType: 'possible',
				jejuDeliveryPrice: '5,000',
				etcDeliveryType: 'possible',
				etcDeliveryPrice: '5,000',

				isBundleDelivery: false,
				bundleDeliveryCount: 0,

				productName: '',
				productOption1: '',
				productOption2: '',
				all: {},
				allTarget: 0,
				monthlyMaxCount: 0,
				productDeliveryType: 2,

				sponsoredSupplyPrice: '',
				purchasePrice: '',

				supplyPriceAll: '',
				hottistChargeAll: '',
				hottistCharge: '',

				mainImage: null,
				priceImage: null,
			},
			data = {
				partner: '',
				isEdit: false,

				isPossibleNormal: true,
				isPossibleRental: false,
				isPossibleSetDeliveryPrice: true,

				inf,
				origin: {
					images: {},
					productGroup: {},
					products: [],
					rentalProducts: [],
					store: {},
				},

				rentalRatioOpts: [],

				childTag: {},
			}

		if (!isPartner) {
			input.basicClass = 'e'
			input.productGroupClass = 'e'

			data = {
				...data,

				isEssentialChangeCode: false,
				oldProductNum: '',
				productNum: '',
				isCheckDup: true,

				supplyPrice: 0,
				hottistCharge: 0,

				productTypeOpts: [],
				productDeliveryTypeOpts: [],

				history: [],
				historyInf: [
					{ title: '상품코드', key: 'productNum', size: 3 },
					...copyVar(inf.slice(1, 8)).map(v => {
						if (v.key != 'stock') delete v.model
						delete v.func
						delete v.tooltip
						delete v.isSpan
						return v
					}),
					//상품명~내돈내산가
					{ title: '핫티수수료', key: 'hottistCharge', size: 4 },
					{ title: '핫트수수료', key: 'hottCharge', size: 4 },
					{ title: '면세<br>여부', key: 'isTaxFree', model: 'checkBox', disabled: true, size: 2 },
				],
			}
		} else {
			//파트너의 경우 테이블에서 안 보여야 하는 값을 날려준다
			//7~10 내돈내산가~핫트수수료
			//12 추가수수료여부 14 내돈내산여부
			data.inf.splice(14, 1)
			data.inf.splice(12, 1)
			data.inf.splice(7, 4)
		}
		return {
			...data,
			input,
		}
	},
	getConfig() {
		getConfig('rental_month_ratio, product_type, product_delivery_type', false, false).then(
			({ rental_month_ratio, product_type, product_delivery_type }) => {
				ths.$set(
					ths,
					'rentalRatioOpts',
					rental_month_ratio.map(v => {
						return {
							label: v.value + '개월',
							ratio: v.label * 1,
							fee: 1 - v.label * 1,
							value: v.value * 1,
						}
					})
				)
				//ths.baseItem.rentalMonth = ths.rentalRatioOpts[ 0 ]
				//ths.rtinf[ 6 ].options = ths.rentalRatioOpts
				//이렇게 안 해주면 변하는 걸 따라오질 못한다...?
				ths.productTypeOpts = product_type.filter(v => {
					switch (v.value) {
						case '3':
						case '4':
							return false
						default:
							return true
					}
				})
				product_delivery_type.splice(0, 1)
				ths.productDeliveryTypeOpts = product_delivery_type
			}
		)
	},
}
</script>
